<template>
    <div class="app-container">
        <el-form :size="$store.getters.size" :inline="true" :model="queryParams" ref="queryParams">
            <el-form-item label="">
                <el-input type="text" v-model="queryParams.userName" placeholder="账户名称" clearable></el-input>
            </el-form-item>
            <el-form-item label="">
                <el-input type="text" v-model="queryParams.realName" placeholder="真实姓名" clearable></el-input>
            </el-form-item>
            <el-form-item label="">
                <el-input type="text" v-model="queryParams.phone" placeholder="电话" clearable></el-input>
            </el-form-item>
            <el-form-item label="">
                <el-select v-model="queryParams.status" placeholder="状态" clearable>
                    <el-option v-for="item in statusOption" :label="item.label" :value="item.value" :key="item.value"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" @click="handlerQuery">查询</el-button>
            </el-form-item>
        </el-form>
        <el-row :gutter="10" class="mb20">
            <el-col :span="1.5">
                <el-button
                    type="primary"
                    plain
                    v-if="$app.haveRole('system:user:add')"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                >新增</el-button>
                <el-button
                    type="danger"
                    plain
                    v-if="$app.haveRole('system:user:deletes')"
                    :disabled="multipleSelection.length<=0"
                    icon="el-icon-delete"
                    size="mini" @click="handleDeletes">批量删除</el-button>
            </el-col>

        </el-row>
        <el-table
                v-loading="loading"
                :data="tableData"
                row-key="guid"
                @selection-change="handleSelectionChange"
                :size="$store.getters.size"
        >
            <el-table-column type="selection" width="55" align="center"> </el-table-column>
            <el-table-column label="用户编号" prop="id" width="80" align="center"></el-table-column>
            <el-table-column label="用户名" prop="username"></el-table-column>
            <el-table-column label="真实姓名" prop="realName"></el-table-column>
            <el-table-column label="手机号" prop="phone"></el-table-column>
            <el-table-column label="状态" width="80">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.status === 1" effect="plain" size="small">正常</el-tag>
                    <el-tag v-else type="danger" effect="plain" size="small">禁用</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="超级管理员" width="120" align="center">
                <template slot-scope="scope">
                    {{ scope.row.isAdmin === 'Y' ? '是': '否'}}
                </template>
            </el-table-column>
            <el-table-column label="创建日期" prop="createTime"></el-table-column>
            <el-table-column width="400" label="操作">
                <template slot-scope="scope" v-if="scope.row.id !== 1">
                    <el-button type="text" @click="handleUpdateStatus(scope.row)">{{scope.row.status===1?'禁用':'启用'}}</el-button>
                    <el-button type="text" icon="" @click="handleResetPwd(scope.row)"
                               v-if="$app.haveRole('system:user:reset')">重置密码</el-button>
                    <el-button type="text" icon="el-icon-edit" @click="handleUpdate(scope.row)"
                               v-if="$app.haveRole('system:user:edit')">修改</el-button>
                    <el-button type="text" icon="el-icon-delete" @click="handleRemove(scope.row)"
                               v-if="$app.haveRole('system:user:delete')">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <page-item :total="total" ref="pageItem"/>


        <el-dialog :title="title" :visible.sync="open" width="680px" append-to-body :close-on-click-modal="false" top="7vh">
            <el-row :gutter="15">
                <el-form :model="form" ref="form" :rules="rules" label-width="100px" :size="$store.getters.size">
                    <el-col :span="24">
                        <el-form-item label="头像" prop="headImg">
                            <el-upload
                                    class="avatar-uploader"
                                    accept=".jpg, .jpeg, .png, .gif, .JPG, .JPEG, .PNG, .GIF"
                                    :action="$store.getters.uploadUrl"
                                    :on-success="uploadSuccess"
                                    :show-file-list="false">
                                <img v-if="form.headImg" :src="form.headImg" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="账户名称" prop="username">
                            <el-input type="text" placeholder="请输入账户名称" v-model="form.username"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="真实姓名" prop="realName">
                            <el-input type="text" placeholder="请输入真实姓名" v-model="form.realName"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="手机号" prop="phone">
                            <el-input type="text" placeholder="请输入手机号" v-model="form.phone"/>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="状态" prop="status">
                            <el-radio-group v-model="form.status">
                                <el-radio v-for="item in statusOption" :label="item.value" :key="item.value">{{item.label}}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="性别" prop="sex">
                            <el-radio-group v-model="form.sex">
                                <el-radio v-for="item in sexOption" :label="item.value" :key="item.value">{{item.label}}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="超级管理员" prop="isAdmin">
                            <el-radio-group v-model="form.isAdmin">
                                <el-radio label="Y" key="Y">是</el-radio>
                                <el-radio label="N" key="N">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="生日" prop="birthday">
                            <el-date-picker
                                    v-model="form.birthday"
                                    type="date"
                                    value-format="yyyy-MM-dd"
                                    default-value="1999-01-01"
                                    placeholder="请选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="仓库" prop="warehouses">
                            <el-select v-model="form.warehouses" multiple filterable style="width: 100%;">
                                <el-option v-for="(item, index) in warehousesOption" :label="item.label" :key="index" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="角色" prop="roles">
                            <el-select v-model="form.roles" style="width: 100%;" multiple>
                                <el-option v-for="(item, index) in roleOption" :key="index" :label="item.roleName" :value="item.guid"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="备注" prop="remark">
                            <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6}" placeholder="请输入" v-model="form.remark"/>
                        </el-form-item>
                    </el-col>
                </el-form>
            </el-row>


            <div slot="footer" class="dialog-footer">
                <el-button :size="$store.getters.size" type="primary" @click="submitForm" :loading="saveLoading">确 定</el-button>
                <el-button :size="$store.getters.size" @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import PageItem from '@/components/PageItem'
    import {listUser,resetPwd, removeManager,removeManagers,
        updateStatus, getManager, saveManager, updateManager} from '@/api/system/user'
    import {getRoleAll} from '@/api/system/role'

    export default {
        name: "SystemUser",
        components: {PageItem},
        data(){
            const getWarehouse = () =>{
                return this.$store.getters.warehouse;
            }
            return{
                queryParams: {
                    userName: undefined,
                    realName: undefined,
                    phone: undefined,
                    status: undefined
                },
                multipleSelection: [],
                tableData: [],
                total: 0,
                loading: false,
                saveLoading: false,
                title: undefined,
                open: false,
                sexOption: [{label: '男', value : 1},{label: '女', value: 2},{label: '未知',value: 3}],
                statusOption: [{label: '正常', value: 1},{label: '禁用', value: 2}],
                form: {},
                rules:{
                    username: [
                        { required: true, message: "账户名称不能为空", trigger: "blur" }
                    ],
                    realName: [
                        { required: true, message: "真实名字不能为空", trigger: "blur" }
                    ]
                },
                warehousesOption: getWarehouse(),
                roleOption: [],
            }
        },
        mounted() {
            this.getTableData();
            this.getRoles();
        },
        methods: {
            /** 获取表数据 */
            getTableData(){
                if(this.loading){return;}
                this.loading = true;
                this.queryParams.current = this.$refs.pageItem.current;
                this.queryParams.size = this.$refs.pageItem.pageSize;
                listUser(this.queryParams).then( ({code, data}) =>{
                    if(code === 0){
                        this.tableData = data.pageData;
                        this.total = data.total;
                    }
                    this.loading = false;
                }).catch(()=>{})
            },
            /** 查询按钮 */
            handlerQuery(){
                this.$refs.pageItem.current = 1;
                this.getTableData();
            },
            /** 启用/禁用 账户 */
            handleUpdateStatus(row){
                const status = row.status === 1 ? 2 : 1;
                this.$confirm('你确定要'+(status===1?'启用':'禁用')+'该账户?','提示',{
                    type: 'warning'
                }).then(()=>{
                    updateStatus(row.guid, status).then(({code})=>{
                        if(code === 0){
                            row.status = status;
                            this.$message.success("操作成功");
                        }
                    })
                }).catch(()=>{})
            },
            /** 重置密码 */
            handleResetPwd(row){
                this.$confirm("你确定要重置此账户密码?","提示",{type: 'warning'}).then(()=>{
                    resetPwd(row.guid).then(({code})=>{
                        if(code === 0)this.$message.success("重置成功")
                    })
                }).catch(()=>{})
            },
            /** 删除账号 */
            handleRemove(row){
                this.$confirm('你确定要删除此账户?','提示',{type: "warning"}).then(()=>{
                    removeManager(row.guid).then(({code})=>{
                        if(code === 0)this.$message.success("删除成功")
                    })
                })
            },
            /** 保存用户按钮 */
            submitForm(){
                this.$refs['form'].validate(valid => {
                    if(valid){
                        this.saveLoading = true;
                        if(this.form.guid !== undefined){
                            updateManager(this.form).then(({code})=>{
                                this.saveLoading = false;
                                if(code === 0){
                                    this.$message.success("修改成功");
                                    this.getTableData();
                                    this.cancel();
                                }
                            }).catch(()=>{this.saveLoading = false})
                        }else{
                            saveManager(this.form).then(({code})=>{
                                this.saveLoading = false;
                                if(code === 0){
                                    this.$message.success("添加成功");
                                    this.getTableData();
                                    this.cancel();
                                }
                            }).catch(()=>{this.saveLoading = false})
                        }
                    }
                })
            },

            /** 编辑用户 */
            handleUpdate(row){
                this.reset();
                this.open = true;
                this.title = "修改用户";
                getManager(row.guid).then(({code, data})=>{
                    if(code === 0){
                        this.form = data;
                    }
                })
            },
            /** 新增按钮 */
            handleAdd: function(){
                this.reset();
                this.title = "添加用户";
                this.open = true;
            },
            /** 初始表单 */
            reset(){
                this.form = {
                    guid: undefined,
                    username: undefined,
                    realName: undefined,
                    headImg: undefined,
                    status: 1,
                    roles: [],
                    sex: 1,
                    isAdmin: 'N',
                    birthday: undefined,
                    phone: undefined,
                    remark: undefined,
                    warehouses:[]
                }
            },
            /** 关闭弹窗 */
            cancel(){
                this.open = false;
                this.reset();
            },
            /** 列表多选 */
            handleSelectionChange(val){
                this.multipleSelection = val
            },
            /** 批量删除 */
            handleDeletes(){
                this.$confirm('你确定要删除选中数据?','提示',{
                    type: "warning"
                }).then(()=>{
                    if(this.multipleSelection.length<=0){
                        this.$message.error("未选择需要删除的数据")
                    }else{
                        const ids = this.multipleSelection.map(item => {return item.guid})
                        removeManagers(ids).then(({code})=>{
                            if(code === 0){
                                this.$message.success("批量删除成功");
                                this.getTableData();
                            }
                        })
                    }
                })
            },
            /** 文件上传成功 */
            uploadSuccess(response){
                if(response.code === 0){
                    this.form.headImg = response.data;
                }
            },
            /** 获取所有角色 */
            getRoles(){
                getRoleAll().then(({code,data })=>{
                    if(code === 0){
                        this.roleOption = data;
                    }
                })
            }
        }
    }
</script>

<style>

</style>
